<template>
  <div>
    <top-nav :showLogo="false" :title="'BURING '+$t('个人中心')"></top-nav>
    <div class="container">
      <div class="uid">
        UID:{{ userInfo ? userInfo.id : '-' }}
        <img v-if="isHideAddress" class="show_icon" @click="isHideAddress = false"
             src="../../assets/image/index/not_show_icon.png"/>
        <img v-else class="show_icon" @click="isHideAddress = true" src="../../assets/image/index/show_icon.png"/>
      </div>
      <div class="wallet-add">
        {{ $t('钱包地址') }} :
        <span v-if="isHideAddress">
          {{ userInfo ? userInfo.hide_address : '-' }}
        </span>
        <p v-else style="font-size: 0.8rem">{{ userInfo ? userInfo.address : '-' }}</p>

      </div>
    </div>
    <div class="container">
      <div class="title">
        {{ $t('我的资产') }}
      </div>
      <div class="assets-cont">
        <div class="assets-item">
          <div class="number">{{ userInfo ? userInfo.usdt : '-' }}</div>
          <div class="subtitle">USDT</div>
        </div>
        <div class="assets-item" style="border-left: 1px solid #262526;padding-left: 1rem">
          <div class="number">{{ userInfo ? userInfo.bur : '-' }}</div>
          <div class="subtitle">BUR</div>
        </div>
      </div>

      <div class="other-number-cont">
        <div class="other-number">
          <div class="subtitle">{{ $t('种草值') }}</div>
          <div class="number">{{ userInfo ? userInfo.forage : '-' }}</div>
        </div>
        <div class="other-number">
          <div class="subtitle">{{ $t('养草值') }}</div>
          <div class="number">{{ userInfo ? userInfo.power : '-' }}</div>
        </div>
        <div class="other-number">
          <div class="subtitle">{{ $t('拔草值') }}</div>
          <div class="number">{{ userInfo ? userInfo.cny : '-' }}</div>
        </div>

      </div>
    </div>
    <div class="property-cont">
      <div class="property-item" style="float: left">
        <div class="title">
          BL {{$t('银行')}} {{ $t('资产') }}
        </div>
        <div class="subtitle" style="padding-bottom: 0.8rem;">
          {{ $t('BUR') }}
        </div>
        <div class="number">{{ burBalance }}</div>

        <div class="but-cont">
          <div class="but-item" v-if="isBind !== true" @click.capture.stop="activateBank">
            {{ $t('开户') }}
          </div>
        </div>

      </div>
      <div class="property-item" style="float: right">
        <div class="title">
          RWA{{ $t('资产') }}
        </div>
        <div class="subtitle" style="padding-bottom: 0.8rem;">
          {{ $t('种草值') }}
        </div>
        <div class="number">{{ userInfo ? userInfo.forage : '-' }}</div>
        <div class="but-cont">
          <router-link to="/rwa/exchange">
            <div class="but-item">
              {{ $t('兑换') }}
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <div class="operations">

      <div class="operation-item">
        <router-link to="/bank/recharge">
          <img class="icon" src="../../assets/image/bank/icon1.png">
          <div class="title">{{ $t('充值') }}</div>
        </router-link>
      </div>

      <div class="operation-item">
        <router-link to="/bank/propose">
          <img class="icon" src="../../assets/image/bank/icon2.png">
          <div class="title">{{ $t('提出') }}</div>
        </router-link>
      </div>

      <div class="operation-item">
        <router-link to="/bank/exchange">
          <img class="icon" src="../../assets/image/bank/icon3.png">
          <div class="title">{{ $t('闪兑') }}</div>
        </router-link>
      </div>
      <div class="operation-item">
      </div>


    </div>

    <!--    <div class="bottom-cont">-->
    <!--      <router-link to="/rwa/recharge">-->
    <!--        <div class="_but">-->
    <!--          充值种草值-->
    <!--        </div>-->
    <!--      </router-link>-->
    <!--    </div>-->

  </div>
</template>

<script>
import TopNav from '@/components/topNav.vue'
import {mapState} from "vuex";
import {activateBank} from "../../api";
import {levelPeriod} from "@/utils/tools";

export default {
  name: "personal",
  components: {TopNav},
  data() {
    return {
      isHideAddress: true,
      burBalance: 0,
      isBind: false,
      bank_level_img: 'grade0.png'
    }
  },
  created() {
    this.getUserInfo()
    this.getAccount()
    this.getInitData()
  },
  computed: {
    ...mapState({
      userInfo: state => state.app.userInfo,
    })
  },
  methods: {

    async getAccount() {
      await this.$store.dispatch('bur/getAccount')
          .then(res => {
            if (res) {
              this.account = res
              console.log('account----', res)
            }
          })
    },

    async getInitData() {
      let that = this;

      this.$toast.loading({
        message: '',
        duration: 0,
      });

      await this.getAccount()
      await this.getUserBind()

      that.burBalance = await this.$store.dispatch('bur/getBurBalance', that.account) //钱包余额
      console.log('钱包余额-----getBurBalance', that.burBalance)


      that.$toast.clear()
    },
    async getUserBind() {
      let that = this;
      await this.$store.dispatch('bur/getBinds', that.account)
          .then(res => {
            that.isBind = res

            console.log('that.isBind-------', that.isBind)
          })
    },

    activateBank() {
      this.$router.push({
        path: '/bank/binding'
      })
    },

    // activateBank() {
    //   let that = this;
    //   this.$toast.loading({
    //     message: '',
    //     duration: 0,
    //   });
    //   activateBank()
    //       .then(({data}) => {
    //         console.log(data);
    //         if (data.code == 200) {
    //           that.getUserInfo()
    //           setTimeout(() => {
    //             that.$toast(that.$t('开户成功'));
    //           }, 500)
    //         } else {
    //           setTimeout(() => {
    //             that.$toast(data.message);
    //           }, 500)
    //         }
    //       })
    //       .finally(() => {
    //         that.$toast.clear()
    //       })
    // },
    getUserInfo() {
      this.$toast.loading({
        message: '',
        duration: 0,
      });
      this.$store.dispatch('app/getUserInfo')
          .then((res) => {
            console.log(res);
            this.bank_level_img = 'grade'+ res.data.bank_level +'.png'
            this.getImageUrl(this.bank_level_img)
            console.log('bank_level_img-----', this.bank_level_img)
          })
          .finally(() => {
            this.$toast.clear()
          })
    },
    getImageUrl(filename) {
      return require(`../../assets/image/bank/grade/${filename}`);
    },
  }
}
</script>

<style scoped lang="less">
.container {
  margin: 0.7rem 4vw 0 4vw;
  padding: 1.6rem 1.3rem;
  background: #1B1B1B;
  border-radius: 1rem;
}

.uid {
  font-size: 1.29rem;
  color: #E7BD71;
}

.show_icon {
  padding-left: 3vw;
  width: 1.5rem;
}

.wallet-add {
  padding-top: 0.5rem;
  font-size: 0.86rem;
  color: #E7BD71;
  line-height: 1.5rem;
}

.title {
  font-size: 1rem;
  color: #E7BD71;
  margin-bottom: 1.8rem;
}

.subtitle {
  font-size: 0.86rem;
  color: #6E6045;
}


.property-cont {
  margin: 0.7rem 4vw 0 4vw;
  overflow: hidden;

  .property-item {
    width: 48%;
    //height: 18.68rem;
    box-sizing: border-box;
    padding: 1.6rem 1.3rem;
    background: #1B1B1B;
    border-radius: 1rem;
  }
}

.but-cont {
  margin-top: 1.8rem;
  height: 2.14rem;

  .but-item {
    display: inline-block;
    min-width: 5.89rem;
    height: 2.14rem;
    padding: 0 0.3rem;
    box-sizing: border-box;
    line-height: 2.14rem;
    color: #000000;
    text-align: center;
    background: linear-gradient(-90deg, #E8BD70 0%, #EDD185 100%);
    border-radius: 1.07rem;
  }
}

.assets-cont {
  display: flex;

  .assets-item {
    flex: 1;
    box-sizing: border-box;

  }
}

.number {
  padding-top: 0.3rem;
  font-size: 1.43rem;
  font-weight: bold;
  color: #E7BD71;
}

.other-number-cont {
  margin-top: 1.5rem;
  display: flex;

  .other-number {
    flex: 1;
    padding-left: 1rem;
    position: relative;

    .number {
      font-size: 1rem;
      font-weight: 400;
      color: #F3C977;
    }
  }

  .other-number::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 70%;
    background: #262526;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .other-number:first-child {
    padding-left: 0;
  }

  .other-number:first-child:after {
    width: 0;
  }
}

.operations {
  margin: 0.7rem 4vw 0 4vw;
  padding-top: 0.8rem;
  display: flex;

  .operation-item {
    flex: 1;
    text-align: center;

    .icon {
      width: 2.86rem;
    }

    .title {
      padding-top: 0.6rem;
      font-size: 0.93rem;
      color: #B5904E;
    }
  }

}

.bottom-cont {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 0.8rem 4vw;
  box-sizing: border-box;
  background: #151515;

  ._but {
    text-align: center;
    font-size: 1.29rem;
    color: #000000;
    line-height: 3.14rem;
    background: linear-gradient(270deg, #E8BD70 0%, #EDD185 100%);
    border-radius: 1.57rem;
  }
}
</style>
